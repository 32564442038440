<template>
  <div class="single-vendor shadow-sm">
    <template v-if="message">
      <div class="row">
        <div class="col-12 w-100 d-flex justify-content-center py-3">
          <div class="round-loader">
            <div class="round-loader__content round-loader__content--1"></div>
            <div class="round-loader__content round-loader__content--2"></div>
          </div>
          {{ message }}
        </div>
      </div>
    </template>
    <div class="upper-block">
      <!-- <div class="approved-status approved">Approved</div> -->
      <div class="rider-profile">
        <div class="user-block">
          <div
            v-if="region === 'PAKISTAN'"
            class="user-image"
            style="position: relative"
          >
            <span
              class="online-status"
              :class="
                vendor !== undefined && vendor.active ? 'online' : 'offline'
              "
            ></span>
            <img
              :src="
                vendor !== undefined && vendor.storeLogo
                  ? vendor.storeLogo
                  : '/media/users/user1.jpg'
              "
              alt="user image"
            />
          </div>
          <div v-if="region === 'US'" class="user-image">
            <img
              :src="
                vendor !== undefined && vendor.storeLogo
                  ? vendor.storeLogo
                  : '/media/users/user1.jpg'
              "
              alt="user image"
            />
          </div>
          <div class="user-name">
            {{ vendor.storeName }}
          </div>
        </div>
        <div class="info-rating-block">
          <span class="info-heading">Rating</span>
          <div class="info">
            <span class="ratings-block">
              <i
                v-for="index in 5"
                class="fa fa-star"
                v-bind:class="getGoldClass(rating, index)"
                :key="index"
              ></i>
            </span>
            <span style="margin-left: 2.5px">{{ rating }}</span>
          </div>
        </div>
      </div>
      <div class="rider-info">
        <div class="info-blocks">
          <div class="info-block">
            <div class="info">
              <div class="heading">Partner ID#</div>
              <div v-if="region === 'PAKISTAN'" class="text">
                {{
                  vendor !== undefined && vendor.storeCode
                    ? vendor.storeCode
                    : "0"
                }}
              </div>

              <div v-if="region === 'US'" class="text">
                {{
                  vendor !== undefined && vendor.employeeNumber
                    ? vendor.employeeNumber
                    : "0"
                }}
              </div>
            </div>
            <template v-if="vendor.isContract">
              <div class="info">
                <div class="heading">SSN</div>
                <div class="text">
                  {{ vendor.ssn }}
                </div>
              </div>
            </template>
          </div>
          <div class="info-block">
            <template v-if="vendor.isContract">
              <div class="info">
                <div class="heading">Contact</div>
                <div class="text">
                  {{ vendor.phone }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="info-blocks single">
          <div class="info-block">
            <div class="info">
              <div class="heading">Address</div>
              <div class="text">
                {{ vendor.storeAddress && vendor.storeAddress.address }}
              </div>
            </div>
            <div v-if="region === 'PAKISTAN'" class="info">
              <div class="heading">Sub Zone</div>
              <div class="text">{{ subzoneVendor }}</div>
            </div>
            <div v-if="region === 'US'" class="info">
              <div class="heading">Zip Code</div>
              <div class="text">{{ vendor.zipCode }}</div>
            </div>
          </div>
          <div class="info-block">
            <div class="info">
              <div class="heading">State</div>
              <div class="text">{{ vendor.state }}</div>
            </div>
          </div>
        </div>
        <div class="info-controls">
          <span class="info-control edit" @click="editVendor(index)">Edit</span>
          <span
            class="info-control archive"
            :class="message !== '' ? 'archive--disabled' : ''"
            v-if="!singleVendor.archived"
            :disabled="message !== ''"
            @click="archiveVendor(true)"
            >Archive</span
          >
          <span
            class="info-control unarchive"
            :class="message !== '' ? 'unarchive--disabled' : ''"
            @click="archiveVendor(false)"
            v-if="singleVendor.archived"
            :disabled="message !== ''"
            >Unarchive</span
          >
        </div>
      </div>
    </div>
    <div class="performance-block">
      <div class="performance-header">Performance</div>
      <div class="list-block shadow-sm">
        <ul class="details-list head">
          <li class="details-list-item">Orders</li>
          <li class="details-list-item">Avg. Order per day</li>
          <li class="details-list-item">Total Revenue</li>
        </ul>
        <ul class="details-list">
          <li class="details-list-item">{{ totalCompletedOrders }}</li>
          <li class="details-list-item">{{ avgOrdersPerDay }}</li>
          <li v-if="region === 'PAKISTAN'" class="details-list-item">
            PKR{{ totalRevenue }}
          </li>
          <li v-if="region === 'US'" class="details-list-item">
            ${{ totalRevenue }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ARCHIVE_STORE } from "@/core/services/store/stores.module";
import { mapState } from "vuex";
export default {
  props: {
    isArchived: Boolean,
    singleVendor: Object,
    index: Number,
  },
  data() {
    return {
      vendor: {},
      rating: 0,
      totalCompletedOrders: 0,
      avgOrdersPerDay: 0,
      totalRevenue: 0,
      message: "",
    };
  },
  mounted() {
    this.vendor = this.singleVendor;
    if (this.vendor !== undefined && this.vendor.avgRating !== undefined) {
      this.rating =
        this.vendor.avgRating % 1 != 0
          ? this.vendor.avgRating
          : this.vendor.avgRating + ".0";
      this.totalCompletedOrders = this.vendor.totalCompletedOrders;
      this.avgOrdersPerDay = this.vendor.avgOrdersPerDay;
      this.totalRevenue = this.vendor.totalRevenue;
    } else {
      this.rating = 0;
    }
  },
  methods: {
    // ...mapGetters(["getAllSubzoneStores"]),
    getGoldClass: function (total, key) {
      return key <= total ? "gold" : "";
    },
    editVendor: function (index) {
      this.$emit("editVendor", index);
    },
    archiveVendor(archive = true) {
      if (archive === true) {
        this.message = "Archiving the store.";
        this.$store
          .dispatch(ARCHIVE_STORE, this.vendor._id)
          .then(() => {
            this.message = "Store was archived.";
            setTimeout(() => {
              this.$emit("update");
              this.message = "";
            }, 200);
          })
          .catch((error) => {
            this.message = error;
          });
      } else {
        this.$emit("archiveAction");
      }
    },
  },
  computed: {
    ...mapState({
      subzoneVendor: (state) => state.stores.specificSubzone,
      region: (state) => state.region.isRegion,
    }),
  },
};
</script>

<style lang="scss">
.round-loader {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: 20px;
  background: #fff;
  &__content {
    &--1 {
      top: 10px;
      left: 2px;
      animation-name: spin-1;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    &--2 {
      animation-name: spin-2;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      bottom: 10px;
      right: 2px;
    }
    border-radius: 50%;
    height: 10px;
    width: 10px;
    position: absolute;
    background: #f8b040;
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-1 {
  0% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes spin-2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.single-vendor {
  width: 100%;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px auto;

  .upper-block {
    transition: all 400ms;
    display: flex;
    padding: 15px;
    padding-top: 35px;
    position: relative;
    .approved-status {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      border-bottom-right-radius: 5px;
      &.approved {
        background: #6dff4c;
        color: #fff;
      }
    }
    .rider-profile {
      width: 25%;
    }
    .rider-info {
      width: 75%;
      .info-blocks {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .info-block {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          padding: 2.5px 5px;
          .info {
            padding: 3.5px 0;
            display: flex;
            justify-content: space-between;
            .heading {
              width: 40%;
              font-weight: 600;
            }
            .text {
              width: 60%;
            }
          }
        }
        &.single {
          display: flex;
          padding: 2.5px;
          margin-bottom: 10px;
          .heading,
          .text {
            display: inline-flex;
          }
          .heading {
            font-weight: 600;
            margin-right: 10px;
          }
        }
      }
      .info-controls {
        display: flex;
        .info-control {
          padding: 5px;
          margin-right: 10px;
          width: 100px;
          text-align: center;
          cursor: pointer;
          border-radius: 15px;
          &.edit {
            background: #4ff86f;
            color: #fff;
          }
          &.archive {
            background: #f8b040;
            color: #fff;
            &--disabled {
              background: lighten($color: #f8b040, $amount: 0.5);
            }
          }
          &.unarchive {
            background: #3fa9fb;
            color: #fff;
            &--disabled {
              background: lighten($color: #3fa9fb, $amount: 0.5);
            }
          }
        }
      }
    }
  }
  .rider-profile {
    .user-block {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0;
      flex-direction: column;
      .user-image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: 55px;
          width: 55px;
          border-radius: 50%;
        }
        .online-status {
          position: absolute;
          height: 18px;
          width: 18px;
          bottom: -3px;
          right: -2px;
          border: 3px solid #fff;
          border-radius: 50%;
          &.online {
            background: #00bc00;
          }
          &.offline {
            background: #d8102a;
          }
        }
      }
      .user-name {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 14px;
        text-align: center;
      }
    }
    .info-rating-block {
      display: flex;
      flex-direction: column;
      .info-heading {
        font-size: 12px;
        text-transform: uppercase;
        color: #ddd;
        padding: 5px 0;
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        .ratings-block {
          i.gold {
            color: #ff9500;
          }
        }
      }
    }
  }

  .performance-block {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    transition: all 400ms;
    .performance-header {
      background: #4e71e1;
      text-align: center;
      color: #fff;
      text-transform: capitalize;
      padding: 15px;
      font-size: 16px;
    }
    .list-block {
      width: 100%;
      margin-bottom: 15px;
      ul.details-list {
        list-style: none;
        width: 100%;
        padding: 10px;
        display: flex;
        margin-bottom: 0;
        &.head {
          font-weight: 700;
        }
        li.details-list-item {
          &:nth-child(1) {
            width: 25%;
          }
          &:nth-child(2) {
            width: 60%;
          }
          &:nth-child(3) {
            width: 15%;
          }
        }
      }
    }
    &.overlay-active {
      filter: blur(5px);
      pointer-events: none;
    }
  }
}
</style>
