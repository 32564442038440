<template>
  <div>
    <b-modal
      id="unarchive-vendor-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="unarchive-vendor-modal"
      @hide="closeModal"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('unarchive-vendor-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Update Partner</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit="unArchiveStore">
        <div class="row py-4">
          <div class="col-12">
            <label class="input-label" for="store-type"
              >Products to unarchive with store</label
            >
            <multi-select
              required
              v-model="form.products"
              :options="productsOptions"
              :reduce-display-property="(option) => option.name"
              :reduce-value-property="(option) => option._id"
              no-options-text="No products"
              selected-no-options-text="No product selected"
            />
          </div>
        </div>
        <div class="row py-4">
          <div class="col-12 d-flex justify-content-end">
            <label class="input-label" for="alert"
              >This will also unarchive subcategories related to the
              products.</label
            >
          </div>
        </div>
        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import vMultiselectListbox from "vue-multiselect-listbox";
import "vue-multiselect-listbox/dist/vue-multi-select-listbox.css";
export default {
  components: {
    "multi-select": vMultiselectListbox,
  },
  props: {
    vendorToEdit: Object,
  },
  data() {
    return {
      form: {
        products: [],
      },
      productsOptions: [],
      processingRequest: "",
      hasError: false,
      processingError: "",
    };
  },
  async mounted() {
    this.form.store = this.vendorToEdit._id;
    const { data: response } = await ApiService.get(
      `/store/${this.vendorToEdit._id}/products/archived`
    );
    if (response.success) {
      this.productsOptions = response.products;
    } else {
      this.hasError = false;
      this.processingError =
        "Error while getting products and subcategories against the store.";
    }
  },
  methods: {
    showModal: function () {
      this.$bvModal.show("unarchive-vendor-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("unarchive-vendor-modal");
      this.$emit("close");
    },

    unArchiveStore: async function (e) {
      e.preventDefault();
      if (this.form.products.length < 1 && !this.vendorToEdit.isContract) {
        this.hasError = "d-flex";
        this.processingError =
          "A store needs at least one product to be unarchived.";
        this.processingRequest = "";
        setTimeout(() => {
          this.hasError = "d-none";
          this.processingError = "";
        }, 5000);
        return;
      }
      this.processingRequest = "processing";
      ApiService.post("/store/unArchive", this.form)
        .then(({ data: res }) => {
          if (res.success) {
            this.$emit("unArchivedStore", res.message);
            this.processingRequest = "";
          }
        })
        .catch(({ data }) => {
          this.hasError = "d-flex";
          this.processingError =
            "Error while updating user info.<br/>" +
            data.message +
            " on " +
            data.url;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
        })
        .catch(() => {
          this.processingRequest = "";
          ApiService.setHeader("content-type", "application/json");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .msl-multi-select {
  width: 100%;
}
::v-deep .unarchive-vendor-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .vendor-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .input-label {
    font-weight: 600;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;

      span.icon {
        &.attachment {
          display: flex;
          .text {
            margin-left: 10px;
            max-width: 170px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  /* .form-group {
    .form-check {
    }
  } */
}
</style>
