<template>
  <div>
    <b-modal
      id="edit-vendor-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="edit-vendor-modal"
      @hide="resetForm"
    >
      <span class="close-btn shadow-sm" @click="closeEditVendor">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">Edit Partner PK</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit.prevent="createNewVendor" @reset="resetForm">
        <div class="row py-4">
          <!-- IMAGE ROW -->
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Partner Logo</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="vendorImage"
                id="vendor-image-input"
                class="mt-3"
                ref="vendorImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="storeLogoUrl !== null">
                <img :src="storeLogoUrl" alt="Level image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-background-image" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Background Image</span>
              </label>
              <input
                @change="uploadProfile"
                type="file"
                name="vendorProfileImage"
                id="vendor-background-image"
                class="mt-3"
                ref="vendorProfileImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="profileImageUrl !== null">
                <img :src="profileImageUrl" alt="Profile image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <!-- IMAGE ROWS END -->
        <div class="row py-4">
          <div class="col-12">
            <b-form-checkbox
              id="is-contract-checkbox"
              v-model="form.isContract"
              name="is-contract-checkbox"
              :value="true"
              :unchecked-value="false"
            >
              Contracted Vendor
            </b-form-checkbox>
          </div>
        </div>
        <div class="row">
          <!-- Store Name -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="store-name">Store Name</label>
            <b-form-input
              required
              v-model="form.storeName"
              placeholder="Store Name"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Phone number -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="phone">Phone</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['pk']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              required
              defaultCountry="PK"
              v-model="phone"
              :disabled="!form.isContract"
            ></vue-tel-input>
          </div>
          <div class="col-md-12">
            <label class="input-label" for="owner-name"
              >Email for invoicing</label
            >
            <b-form-input
              required
              v-model="form.email"
              type="email"
              placeholder="Email for invoicing"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label class="input-label" for="store-type">Owner Name </label>
            <b-form-input
              required
              type="text"
              v-model="form.ownerName"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label class="input-label" for="store-type">Owner CNIC </label>
            <b-form-input
              required
              type="text"
              @keyup="cnicKey"
              v-model="form.cnic"
              minlength="15"
              maxlength="15"
            ></b-form-input>
          </div>
          <!-- Store Address -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="address">Address</label>
            <ValidateAddress
              v-if="form.address"
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              ref="validateRef"
            />
          </div>
          <!--  -->
          <!-- Business Line -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="store-type">Business Line</label>
            <b-form-select v-model="form.businessLine" :options="businessLines">
            </b-form-select>
          </div>
          <!--  -->
          <div class="col-md-6">
            <label for="form-invoicing-options">Invoicing method</label>
            <b-form-select
              required
              v-model="form.invoicingMethod"
              :options="invoicingOptions"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <label class="input-label" for="store-type"
              >Operating margin on products
            </label>
            <b-form-input
              required
              type="number"
              v-model="form.operatingMargin"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="delivery-charges-primary" class="input-label"
              >Primary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in primary zone"
              v-model="form.deliveryChargesPzone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Secondary zone delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in secondary zone"
              v-model="form.deliveryChargesSzone"
              required
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="" class="input-label"
              >Other zones delivery charges</label
            >
            <b-form-input
              type="number"
              placeholder="Delivery charges in other zones"
              v-model="form.deliveryChargesOzone"
              required
            ></b-form-input>
          </div>
          <!-- Reach store distance -->
          <div class="col-md-6 py-4 place-center">
            <b-form-checkbox
              id="default-acceptable-range"
              v-model="form.defaultAcceptableRange"
              name="default-acceptable-range"
              @change="changeRange"
              :value="true"
              :unchecked-value="false"
            >
              Default acceptable range
            </b-form-checkbox>
          </div>
          <!--  -->
          <!-- Reach store distance -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="ssn">Reach Store Distance</label>
            <b-form-input
              required
              v-model="form.acceptableRange"
              type="number"
              :disabled="form.defaultAcceptableRange"
              placeholder="Acceptable Range"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Description -->
          <div class="col-12">
            <label class="input-label" for="store-type">Description</label>
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
              e
            ></b-form-textarea>
          </div>
          <!--  -->
        </div>
        <span
          class="d-none justify-content-center text-danger"
          :class="hasError"
          >{{ processingError }}</span
        >
        <!-- Submit Button -->
        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Update
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { GET_ACCETABLE_RANGE } from "@/core/services/store/settings-us.module";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";
import ValidateAddress from "@/view/pages/riders/ValidateAddress";
export default {
  components: {
    ValidateAddress,
  },
  data() {
    return {
      form: {
        name: "",
        address: "",
        storeName: "",
        ownerName: "",
        isContract: false,
        description: "",
        longitude: -71.5,
        latitude: 44.0,
        cnic: "",
        email: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        invoicingMethod: "",
        operatingMargin: "",
        deliveryChargesPzone: "",
        deliveryChargesSzone: "",
        deliveryChargesOzone: "",
      },
      invoicingOptions: ["Daily", "Weekly", "Fortnightly", "Monthly"],
      vendorImage: null,
      imageUrl: null,
      phone: "",
      profileImage: null,
      profileImageUrl: null,
      storeLogoUrl: null,
      docImage: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
    };
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
      acceptableRange: ({ settingsUs: settings }) => settings.acceptableRange,
      businessLines: (state) => {
        const arr = [];
        arr.push({ text: "Business Lines", value: null });
        state.businessLines.allBusinessLines.map((item) => {
          arr.push({ text: item.name, value: item._id });
        });
        return arr;
      },
    }),
  },
  async mounted() {
    await this.$store.dispatch(GET_ALL_BUSINESS_LINES);
    await this.$store.dispatch(GET_ACCETABLE_RANGE);
    const val = this.vendorToEdit;
    this.form.storeName = val.storeName;
    this.form.userType = "VENDOR";
    this.form.businessLine = val.businessLine;
    this.form.operatingMargin = val.operatingMargin;
    this.form.deliveryChargesPzone = val.deliveryCharges.primaryZone;
    this.form.deliveryChargesSzone = val.deliveryCharges.secondaryZone;
    this.form.deliveryChargesOzone = val.deliveryCharges.others;
    this.form.address = val.storeAddress.address;
    this.form.ownerName = val.owner.name;
    this.form.cnic = val.owner.cnic;
    this.form.storeId = val._id;
    this.form.isContract = val.isContract;
    this.phone = val.phone.replace("+92", "0");
    this.form.defaultAcceptableRange = val.defaultAcceptableRange || false;
    this.form.acceptableRange = val.acceptableRange || 0;
    this.profileImageUrl = val.profileImage;
    this.storeLogoUrl = val.storeLogo;
    this.form.invoicingMethod = val.invoicingMethod;
    this.form.email = val.invoiceEmail;
    this.form.longitude = val.storeAddress.coordinates[0];
    this.form.latitude = val.storeAddress.coordinates[1];
    this.form.description = val.description;
    setTimeout(() => {
      this.$refs.validateRef.parentSubmittable();
    }, 100);
  },
  props: {
    vendorToEdit: Object,
  },
  methods: {
    ...mapGetters(["getAllBusinessLines"]),
    changeRange(val) {
      if (val === true) {
        this.form.acceptableRange = this.acceptableRange.value;
      }
    },
    showModal: function () {
      this.$bvModal.show("edit-vendor-modal");
    },
    closeEditVendor: function () {
      this.$bvModal.hide("edit-vendor-modal");
      this.$emit("closeEditVendor");
    },
    cnicKey: function (evt) {
      // Only ASCII character in that range allowed
      let { cnic } = this.form;
      cnic = cnic.split("-").join("");
      var ASCIICode = evt.which ? evt.which : evt.keyCode;
      if (cnic.length > 0) {
        if (
          ASCIICode !== 189 &&
          ASCIICode > 31 &&
          (ASCIICode < 48 ||
            (ASCIICode > 57 && ASCIICode < 96) ||
            ASCIICode > 105)
        ) {
          cnic = cnic.slice(0, cnic.length - 1);
        }
        let first = "";
        let second = "";
        let third = "";
        let string = cnic;
        if (cnic.length > 5) {
          first = cnic.slice(0, 5);
          string = `${first}-`;
          if (cnic.length > 12) {
            second = cnic.slice(5, 12);
            string = `${string}${second}-`;
            if (cnic.length > 12) {
              third = cnic.slice(12, 13);
              string = `${string}${third}`;
            }
          } else {
            second = cnic.slice(5);
            string = `${string}${second}`;
          }
        }
        this.form.cnic = string;
      }
      return true;
    },

    uploadFile: function () {
      this.vendorImage = this.$refs.vendorImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.vendorImage);
    },
    uploadProfile: function () {
      this.profileImage = this.$refs.vendorProfileImageRef.files[0];
      this.profileImageUrl = URL.createObjectURL(this.profileImage);
    },
    uploadAttach: function () {
      this.docImage = this.$refs.docImageRef.files[0];
    },
    changeLine: function (val) {
      if (val !== null) {
        this.form.storeType = this.businessLines.find(
          (b) => b.value === val
        ).text;
      }
    },
    resetForm: function () {
      const defaultForm = {
        phone: "",
        storeName: "",
        description: "",
        isContract: false,
        state: "MN",
        ssn: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        address: "",
        zipCode: "",
        longitude: "",
        latitude: "",
        placeId: "",
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.imageUrl = null;
      this.$emit("resetRider");
    },
    beforeSubmission: function () {
      let newItem = this.businessLines.filter(
        (item) => item.value === this.form.businessLine
      );
      this.form.storeType = newItem[0].text;
    },
    recieveAddress: function (obj) {
      this.form.address = obj.address;
      this.form.placeId = obj.placeId;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
    },
    createNewVendor: async function (e) {
      e.preventDefault();
      const temp = this.phone.replace("+92", "");
      this.form.phone = `+92${parseInt(
        temp
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", ""),
        10
      )}`;
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.beforeSubmission();
      this.processingRequest = "processing";
      let newPassword = "";
      ApiService.post("/store/editStore", this.form)
        .then((res) => {
          if (res.data.success) {
            if (res.data.password !== null) {
              newPassword = res.data.password;
            }
            if (this.vendorImage !== null) {
              ApiService.setHeader("content-type", "multipart/form-data");
              const formData = new FormData();
              formData.append("image", this.vendorImage);
              formData.append("storeId", this.form.storeId);
              ApiService.post("/store/uploadImage", formData)
                .then(() => {
                  if (this.profileImage !== null) {
                    this.uploadProfileImage(res.data.data._id, tk);
                  } else {
                    ApiService.setHeader("content-type", "application/json");
                    this.resetForm();
                    this.$emit("editedVendor", newPassword);
                    this.processingRequest = "";
                  }
                })
                .catch(({ data }) => {
                  this.hasError = "d-flex";
                  this.processingError =
                    "Error while uploading new image.<br/>" +
                    data.message +
                    " on " +
                    data.url;
                  this.processingRequest = "";
                  setTimeout(() => {
                    this.hasError = "d-none";
                    this.processingError = "";
                  }, 3000);
                });
            } else {
              if (this.profileImage !== null) {
                this.uploadProfileImage(res.data.data._id, tk);
              } else {
                ApiService.setHeader("content-type", "application/json");
                this.resetForm();
                this.$emit("editedVendor", newPassword);
                this.processingRequest = "";
              }
            }
          } else {
            this.hasError = "d-flex";
            this.processingRequest = "";
            this.processingError =
              res.data.error.message || "Unknown error while updating vendor.";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
          }
        })
        .catch(({ data }) => {
          this.hasError = "d-flex";
          this.processingError =
            "Error while updating user info.<br/>" +
            data.message +
            " on " +
            data.url;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
        })
        .catch(() => {
          this.processingRequest = "";
          ApiService.setHeader("content-type", "application/json");
        });
    },
    uploadProfileImage: function (storeId) {
      ApiService.setHeader("content-type", "multipart/form-data");
      const formData2 = new FormData();
      formData2.append("image", this.profileImage);
      formData2.append("storeId", storeId);
      ApiService.post("/store/updateProfilePicture", formData2)
        .then((res2) => {
          ApiService.setHeader("content-type", "application/json");
          if (res2.data.success === 0) {
            this.hasError = "d-flex";
            this.processingError = res2.data.message;
            this.processingRequest = "";
            setTimeout(() => {
              this.hasError = "d-none";
              this.processingError = "";
            }, 3000);
            return;
          }
          this.resetForm();
          this.$emit("editedVendor");
          this.processingRequest = "";
        })
        .catch(() => {
          this.processingRequest = "";
          ApiService.setHeader("content-type", "application/json");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .edit-vendor-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;
    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .vendor-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .input-label {
    font-weight: 600;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;
      span.icon {
        &.attachment {
          display: flex;
          .text {
            margin-left: 10px;
            max-width: 170px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  /* .form-group {
    .form-check {
    }
  } */
}
</style>
