<template>
  <div>
    <b-modal
      id="new-vendor-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="new-vendor-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-vendor-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Partner US</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <b-form @submit.prevent="createNewVendor" @reset="resetForm">
        <div class="row py-4">
          <!-- IMAGE ROW -->
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Partner Logo</span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="vendorImage"
                id="vendor-image-input"
                class="mt-3"
                ref="vendorImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="imageUrl !== null">
                <img :src="imageUrl" alt="Level image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <div class="row py-4">
          <div class="col-md-6">
            <div class="image-upload">
              <label for="vendor-background-image" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                </span>
                <span class="text">Background Image</span>
              </label>
              <input
                @change="uploadProfile"
                type="file"
                name="vendorProfileImage"
                id="vendor-background-image"
                class="mt-3"
                ref="vendorProfileImageRef"
              />
            </div>
          </div>
          <div class="col-md-6 vendor-image-preview">
            <div class="image-block">
              <template v-if="profileImage !== null">
                <img :src="profileImageUrl" alt="Profile image" />
              </template>
              <template v-else>
                <h6>No image added</h6>
              </template>
            </div>
          </div>
        </div>
        <!-- IMAGE ROWS END -->
        <div class="row py-4">
          <div class="col-12">
            <b-form-checkbox
              id="is-contract-checkbox"
              v-model="form.isContract"
              name="is-contract-checkbox"
              :value="true"
              :unchecked-value="false"
            >
              Contracted Vendor
            </b-form-checkbox>
          </div>
        </div>
        <div class="row">
          <!-- Store Name -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="store-name">Store Name</label>
            <b-form-input
              required
              v-model="form.storeName"
              placeholder="Store Name"
              @change="changeStoreName"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Owner name -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="owner-name">Owner Name</label>
            <b-form-input
              required
              v-model="form.ownerName"
              placeholder="Owner Name"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Phone number -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="phone">Phone</label>
            <vue-tel-input
              disabledFetchingCountry
              :onlyCountries="['us']"
              enabledCountryCode
              :enabledFlags="false"
              validCharactersOnly
              :maxLen="10"
              required
              defaultCountry="US"
              v-model="phone"
              :disabled="!form.isContract"
            ></vue-tel-input>
          </div>

          <!-- Owner SSN -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="ssn">Social Security Number</label>
            <b-form-input
              required
              v-model="form.ssn"
              @keyup="ssnKey"
              type="text"
              pattern="\b\d{3}[-]\d{2}[-]\d{4}\b"
              placeholder="Social Security Number 123-12-1234"
              :disabled="!form.isContract"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Store Address -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="address">Address</label>
            <ValidateAddress
              :addressPassed="form.address"
              @sendAddress="recieveAddress"
              ref="validateRef"
            />
          </div>
          <!--  -->
          <!-- Business Line -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="store-type">Business Line</label>
            <b-form-select v-model="form.businessLine" :options="businessLines">
            </b-form-select>
          </div>
          <!--  -->

          <!-- Reach store distance -->
          <div class="col-md-6 py-4 place-center">
            <b-form-checkbox
              id="default-acceptable-range"
              v-model="form.defaultAcceptableRange"
              name="default-acceptable-range"
              :value="true"
              :unchecked-value="false"
            >
              Default acceptable range
            </b-form-checkbox>
          </div>
          <!--  -->
          <!-- Reach store distance -->
          <div class="col-md-6 py-4">
            <label class="input-label" for="ssn">Reach Store Distance</label>
            <b-form-input
              required
              v-model="form.acceptableRange"
              type="number"
              :disabled="form.defaultAcceptableRange"
              placeholder="Acceptable Range"
            ></b-form-input>
          </div>
          <!--  -->
          <!-- Description -->
          <div class="col-12">
            <label class="input-label" for="store-type">Description</label>
            <b-form-textarea
              id="textarea"
              v-model="form.description"
              placeholder="Description"
              rows="3"
              max-rows="6"
              e
            ></b-form-textarea>
          </div>
          <!--  -->
        </div>
        <!-- CONTRACT -->
        <div class="row p-4" v-if="form.isContract">
          <div class="image-upload">
            <label for="vendor-doc-image" class="image-upload-label">
              <span class="icon attachment">
                <i class="fa fa-paperclip"></i>
                <span class="text">Upload Attachment</span>
              </span>
            </label>
            <input
              @change="uploadAttach"
              type="file"
              name="docImage"
              id="vendor-doc-image"
              class="mt-3"
              ref="docImageRef"
              required
            />
          </div>
        </div>
        <!--  -->
        <!-- Submit Button -->

        <div class="row d-flex justify-content-end p-4">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            >Create
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

import { GET_ACCETABLE_RANGE } from "@/core/services/store/settings-us.module";
import { GET_ALL_BUSINESS_LINES } from "@/core/services/store/businessLines.module";

import ValidateAddress from "@/view/pages/riders/ValidateAddress";

export default {
  components: {
    ValidateAddress,
  },
  async mounted() {
    await this.$store.dispatch(GET_ALL_BUSINESS_LINES);
    await this.$store.dispatch(GET_ACCETABLE_RANGE);
    this.form.acceptableRange = this.acceptableRange.value;
  },
  data() {
    return {
      form: {
        name: "",
        phone: "",
        address: "",
        storeName: "",
        ownerName: "",
        description: "",
        longitude: 0,
        latitude: 0,
        state: "MN",
        ssn: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        userType: "VENDOR",
        defaultAcceptableRange: true,
        isContract: true,
        acceptableRange: 0,
      },
      vendorImage: null,
      imageUrl: null,
      phone: "",
      profileImage: null,
      profileImageUrl: null,

      docImage: null,
      zipcodesOptions: [],
      selectedZipCode: null,
      processingRequest: "",
      hasError: false,
      processingError: "",
    };
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
      acceptableRange: ({ settingsUs: settings }) => settings.acceptableRange,
      businessLines: ({ businessLines }) => {
        const arr = businessLines.allBusinessLines.map((item) => ({
          text: item.name,
          value: item._id,
        }));
        arr.unshift({ text: "Business Lines", value: null });
        return arr;
      },
    }),
  },
  methods: {
    ...mapGetters(["getAllBusinessLines"]),
    ssnKey: function () {
      const value = this.form.ssn;
      var val = value.replace(/\D/g, "");
      val = val.replace(/^(\d{3})/, "$1-");
      val = val.replace(/-(\d{2})/, "-$1-");
      val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
      this.form.ssn = val;
    },
    showModal: function () {
      this.$bvModal.show("new-vendor-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("new-vendor-modal");
    },
    uploadFile: function () {
      this.vendorImage = this.$refs.vendorImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.vendorImage);
    },
    uploadProfile: function () {
      this.profileImage = this.$refs.vendorProfileImageRef.files[0];
      this.profileImageUrl = URL.createObjectURL(this.profileImage);
    },
    uploadAttach: function () {
      this.docImage = this.$refs.docImageRef.files[0];
      // this.imageUrl = URL.createObjectURL(this.vendorImage);
    },

    resetForm: function () {
      const defaultForm = {
        name: "",
        phone: "",
        address: "",
        storeName: "",
        isContract: false,
        ownerName: "",
        description: "",
        longitude: -71.5,
        latitude: 44.0,
        state: "MN",
        ssn: "",
        zipCode: "",
        businessLine: null,
        storeType: null,
        placeId: "",
        userType: "VENDOR",
        email: "",
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.imageUrl = null;
    },
    beforeSubmission: function () {
      if (!this.form.isContract && this.region === "PAKISTAN") {
        // this.form.phone = this.generateRandomDigits();
        this.form.ssn = this.generateRandomDigits();
      }
      let newItem = this.businessLines.filter(
        (item) => item.value === this.form.businessLine
      );
      this.form.storeType = newItem[0].text;
    },
    recieveAddress: function (obj) {
      this.form.address = obj.address;
      this.form.zipCode = obj.zipCode;
      this.form.placeId = obj.placeId;
      this.form.latitude = obj.lat;
      this.form.longitude = obj.lng;
    },
    createNewVendor: async function () {
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.form.phone = `+1${parseInt(
        this.phone
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", ""),
        10
      )}`;
      this.beforeSubmission();
      this.processingRequest = "processing";
      let newPassword = "";
      ApiService.setHeader("content-type", "application/JSON");
      ApiService.post("/user/addUser", this.form)
        .then((res) => {
          if (res.data.success) {
            if (this.form.isContract) {
              newPassword = res.data.user.password;
            }
            if (this.vendorImage !== null) {
              ApiService.setHeader("content-type", "multipart/form-data");
              const formData = new FormData();
              formData.append("image", this.vendorImage);
              formData.append("storeId", res.data.data.data._id);
              formData.append("token", `${tk}`);
              ApiService.post("/store/uploadImage", formData)
                .then((res1) => {
                  if (res1.data.success === 0) {
                    this.hasError = "d-flex";
                    this.processingError = res1.data.message;
                    this.processingRequest = "";
                    setTimeout(() => {
                      this.hasError = "d-none";
                      this.processingError = "";
                    }, 3000);
                    return;
                  }
                  if (this.profileImage !== null) {
                    ApiService.setHeader("content-type", "multipart/form-data");
                    const formData2 = new FormData();
                    formData2.append("image", this.profileImage);
                    formData2.append("storeId", res.data.data.data._id);
                    formData2.append("token", `${tk}`);
                    ApiService.post("/store/updateProfilePicture", formData2)
                      .then((res2) => {
                        ApiService.setHeader(
                          "content-type",
                          "application/json"
                        );
                        if (res2.data.success === 0) {
                          this.hasError = "d-flex";
                          this.processingError = res2.data.message;
                          this.processingRequest = "";
                          setTimeout(() => {
                            this.hasError = "d-none";
                            this.processingError = "";
                          }, 3000);
                          return;
                        }
                        this.resetForm();
                        this.$emit("addedNewVendor", newPassword);
                        this.processingRequest = "";
                      })
                      .catch(() => {
                        this.processingRequest = "";
                        ApiService.setHeader(
                          "content-type",
                          "application/json"
                        );
                      });
                  } else {
                    ApiService.setHeader("content-type", "application/json");
                    this.resetForm();
                    this.$emit("addedNewVendor", newPassword);
                    this.processingRequest = "";
                  }
                })
                .catch(() => {
                  this.processingRequest = "";
                  ApiService.setHeader("content-type", "application/json");
                });
            } else {
              ApiService.setHeader("content-type", "application/json");
              this.resetForm();
              this.$emit("addedNewVendor", newPassword);
              this.processingRequest = "";
            }
          }
        })
        .catch(({ data }) => {
          ApiService.setHeader("content-type", "application/json");
          this.hasError = "d-flex";
          this.processingError = data.message + " on " + data.url;
          this.processingStatusText = this.processingError;
          this.processingRequest = "";
          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
        });
    },
    changeStoreName: function (val) {
      this.form.name = val;
    },
    generateRandomDigits: function () {
      return "98" + (Math.floor(Math.random() * 90000000) + 10000000);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .new-vendor-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .image-upload {
    position: relative;
    label {
      display: flex;
      align-items: center;

      span.icon {
        &.attachment {
          display: flex;
          .text {
            margin-left: 10px;
            max-width: 170px;
            text-align: center;
          }
        }
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-right: 10px;
      }
      span.text {
        font-weight: 700;
        font-weight: 12px;
        display: flex;
        align-items: center;
      }
      margin: 0;
      cursor: pointer;
    }
    input {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
    }
  }
  .vendor-image-preview {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h6 {
      font-size: 10px;
      text-align: center;
    }
    .image-block {
      border-radius: 100%;
      overflow: hidden;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .input-label {
    font-weight: 600;
  }
  .vti__input {
    &:disabled {
      background: #f3f6f9;
    }
  }
}
</style>
